.selecao-background{
    /* background-color: rgba(255, 255, 255, 0.9);   */
    background-color: #3b3b3b;
    width: 500px;
    height: 400px;
    display: flex;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.selecao-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
}

.empresa-btn{
    padding: 10px;
    width: 85%;
    background-color: #fff;
    color: #3d3d3d;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    margin-top: 15px;
    font-size: 90%;
    cursor: pointer;
    text-align: center;
    transition: 0.3s all ease-in-out;
}

.empresa-btn:hover{
    background-color: #646464;
    transition: 0.3s all ease-in-out;
    color: #fff;
}

.login-img{
    width: 10vw;
    min-width: 300px;
}

.politica-privacidade{
    padding: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 60%;
    display: flex;
    text-align: center; 
    color: #fff;
}

@media screen and (max-width: 500px) {


    
}