.login-background{
    background-color: rgba(255, 255, 255, 0.9);  
    width: 20vw;
    height: 20vw;
    display: flex;
    min-width: 500px;
    min-height: 410px;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.login-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
}

.login-input{
    width: 100%;
    padding: 10px;
    outline: none;
    margin-top: 15px;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

.login-header {
    height: 50%;
}

.login-title {
    background-color: #b32e54;
    margin-bottom: 50px;
    color: #fff;
    font-style: italic;
    padding: 1px 1px;
    margin: 5px 0;
}

.login-cad-rec{
    padding-top: 15px;
    color: #3b3b3b;
    width: 80%;    
}

.login-cad-rec a {
    font-size: 13px;
}

.span-cad-rec{
    cursor: pointer;
    color: #b32e54;
    margin-left: 3px;
    transition: 0.3s all;
}

@media screen and (max-width: 500px) {
    .login-background{
        min-width: 350px;
    }

    .login-img{
        min-width: 250px    
    }
}