.tabela-container {
  max-width: 1400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  padding: 10px; 
     
}

.tabela {
  font-family:sans-serif;
  width: 90%;
  height: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  transition: 0.3s; 
  font-size: 15px;   
}

th,
td {
	width: 160px;
  height: 45px;
  border-bottom: 1px solid #adadad
}

.thead-row{
  background-color: #3b3b3b;
  color: white;
}

.trow{
  cursor: pointer;
  transition: 0.3s all ease;
}

.trow:hover{
  background-color: #adadad;
  transition: 0.3s all ease;
}

h1 {
  color: #3b3b3b;
  text-align: left;
}

@media screen and (max-width: 1300px){
  .tabela-container{
    margin-top: 50px;
  }
}

@media screen and (max-width: 1000px){
  .tabela{
    overflow-x: hidden;
    font-size: 10px;
  }

  
}
