.bar-container {
    height: 100%;
    width: 17vw;    
    min-width: 240px;
    background-color: #3b3b3b;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 0;
}

.bar-img{
    width: 100%;
}

.bar-sign-out-btn {
    background-color: #adadad;
    border: none;
    width: 10vw;
    min-width: 200px;
    padding: 10px;
    transition: 0.2s all ease;
}

.bar-sign-out-btn:hover{
    cursor:pointer;
    color: white;
    transition: 0.2s all ease;
}

.div-img{
    display: flex;
    justify-content: center;
    width: 100%;
}

.div-btn{
    display: flex;
    justify-content: center;
    width: 100%;

}

@media screen and (max-width: 1000px) {
    .bar-container {
        display: none;
        position: absolute;
    }    
}
