.filters-container{
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.filters-container select{
    border: none;
    border-bottom: 1px #ADADAD solid;
    width: 100%;
    padding: 8px;
    outline: none;
    margin-right: 10px;
    transition: 0.3s all ease;
    background: none;
    color: white;
    font-size: 17px;
}

.filters-container select::selection{
    background-color: black;
}
  
.filters-container select:hover{
    background-color: #adadad;
    transition: 0.3s all ease;
    color: #3b3b3b;
}
  
.filters-container option{
    background-color: #adadad;
    color: #3b3b3b;
}

.filters-btn{
    padding: 5px;
    width: 200px;
    background-color: white;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    font-style: italic;
    cursor: pointer;
}

