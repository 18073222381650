.dropdown-container{
    background-color: #3d3d3d;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 11px rgba(0,0,0,0.2);
    transition: 500ms all ease-in-out;
}

.dropdown-header{
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #fff; 
    color: #3d3d3d
}


.dropdown-title{
    font-size: 25px;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.dropdown-ul{
    padding: 0;
    list-style: none;
    font-size: 18px;
    color: white;
}

.dropdown-ul li{
    margin-top: 10px;
}

