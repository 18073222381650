.modal {
    width: 70%;
    height: 70%;
    min-width: 600px;
    max-width: 800px;  
}

.sub-title {
    font-weight: bold;
}

.span {
    background-color: #fff;
    padding: 10px 10px 10px 10px;
    background-color: none;
    margin: 5px 0;
    text-align: left;
    border-radius: 3px;
    font-size: 13px;
}

.usuario {
    width: 100%;        
}

.modal-grid {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-info {
    width: 100%;
    height: 80%;
    justify-content: space-evenly;
}

.modal-input {
    padding: 10px;
    outline: none;
    margin: 10px;
    border-radius: 5px;
    border: none;
    width: 70%;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

.password-label{
    font-size: 12px;
    width: 50%;
    min-width: 264px;
}

.company-img {
    width: 280px;
}

.modal-input-cnpj { 
    padding: 10px;
    outline: none;
    margin: 10px 0 10px 0;
    border-radius: 5px;
    border: none;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}
