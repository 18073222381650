.modal-info-motorista{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    max-width: 400px;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;   
    outline: none;
}

.modal-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-header{
    background-color: #3d3d3d;
    color: #fff;
    padding: 0 15px 0px 15px;
    box-sizing: border-box;
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-header h2 {
    font-size: 20px;
    margin-right: 3em;
}

.close-btn:hover{
    cursor: pointer;
}

.modal-body-full{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.modal-body{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 17px;
}

.name{
    font-size: 23px;
}

.modal-body span {
    margin-top: 5px
}

.modal-footer{
    width: 80%;
    height: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.modal-footer button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80%;
    border: none;
    border-radius: 3px;
    padding: 10px;
    background-color: #3d3d3d;
    color: #fff;
    box-shadow: 0 2px 11px rgba(0,0,0,0.2);
    transition: 0.2s all ease-in-out;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}
  
.ReactModal__Overlay--after-open {
    opacity: 1;
}
  
.ReactModal__Overlay--before-close {
    opacity: 0;
}
  
@media screen and (max-width: 500px) {
    .modal-info-motorista {
        width: 95%;
        height: 500px;
    }

}