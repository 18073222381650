.submit-button {
    width: 25%;
    background-color: #3d3d3d;
    color: #fff;
    font-weight: 500;
    padding: 10px;
    border: 1px solid white;
    cursor: pointer;
    text-align: center;
    transition: 0.3s all ease-in-out;
    border-radius: 3px;
}

.submit-button:hover{
    background-color: #646464;
    transition: 0.3s all ease-in-out;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3d3d3d;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.bg-transp {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background {
    background-color: rgba(255, 255, 255, 0.9);  
    display: flex;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.title {
    background-color: #b32e54;
    color: #fff;
    font-style: italic;
    padding: 1px 1px;
    margin: 5px 0;
}