.responsive-background {
    height: 100%;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.responsive-container {
    width: 100%;
    height: 70%;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.flex-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-subtitle h2 {
    margin: 5px;
}

.responsive-container img {
    width: 45vw;
    min-width: 300px;
}

.responsive-container span {
    text-align: center;
    font-size: 15px;
    font-style: italic;
    max-width: 500px;
    width: 80%;
}

.responsive-container input {
    width: 100%;
    padding: 13px 5px 13px 5px;
    border-radius: 3px;
    outline: none
}

.responsive-container input::placeholder {
    font-style: italic;
    font-size: 13px;    
}

.responsive-container button {
    width: 100%;
    padding: 10px 5px 10px 5px;
    background-color: #282828;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 15px;
}

.input-btn {
    width: 85%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.error-msg-container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.error-msg {
    font-size: 13px;
    margin: 5px 0 5px 0;
    color: #a21b1b
}

.error-msg a{
    list-style: none;
    font-size: 15px;
    margin: 5px 0 5px 0;
    color: #a21b1b
}

.error-msg span{
    font-style: none;
}

.company-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.company-info h4 {
    margin: 2px
}

.register-container {
    display: flex;
    height: 80%;
    max-height: 450px;
    justify-content: space-evenly;
    flex-direction: column;
}

.register-form {
    height: 100%;
    max-height: 230px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 90%;
}

.register-form input {
    border: 2px solid #3d3d3d; 
}

.register-form input::placeholder {
    font-size: 8px; 
}

.redirect-btn-to-login{
    text-decoration:underline;
    color: #3d3d3d;
    font-weight: 700;
}


