.paginator-container-driver{
    max-width: 1600px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0
}

.paginator-container-candidate{
    max-width: 1400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0
}

.selected-button{
    list-style: none;
    background-color: #3d3d3d;
    padding: 10px;
    margin-left: 10px;
    color: white;
    border: none;
    cursor: pointer;
}

.other-button{
    list-style: none;
    background-color: #adadad;
    padding: 10px;
    margin-left: 10px;
    margin-right: 15px;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out
}

.other-button:hover{
    transition: 0.3s all ease-in-out;
    background-color: #3d3d3d
}

.break {
    list-style: none;   
}

.previous-next{
    padding: 10px;
    list-style: none;
    background-color: #3d3d3d;
    color: white;
    cursor: pointer;
    transition: 0.3s all ease-in-out
}

.disable-prev-next{
    display: none;
}

.previous-next:hover{
    background-color: #adadad;
    transition: 0.3s all ease-in-out
}

@media screen and (max-width: 500px){
    .paginator-container{
        max-width: 500;
    }
}