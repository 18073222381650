.redirect-btn-container{
    padding: 7px;
    border: 1px solid #fbf23d;
    border-radius: 5px;
}

.redirect-btn{
    color: #fff;
    text-decoration: none;
}

.redirect-btn:hover{

}