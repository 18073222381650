.nav-container{
    background-color: #fbf23d;
    height: 9vh;
    max-height: 60px;
    box-shadow: 0 2px 11px rgba(0,0,0,0.2);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%
}   

.nav-btn-filter{
    visibility: hidden;
    background: none;
    outline: none;
    border: none;
}

.nav-btn-info{
    background: none;
    outline: none;
    border: none;   
    cursor: pointer;
}

@media screen and (max-width: 1300px) {
    .nav-container {
        background-color: #3d3d3d;
        position: fixed;
    }
}


  