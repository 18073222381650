.driver-background{
    width: 50%;
    height: 680px;
    max-width: 510px;
    min-width: 360px;
    max-height: 600px;
}

.driver-div{
    flex-direction: column;
    color: white;
    width: 100%;
    height: 100%;
}

.driver-title{
    margin-bottom: 50px;
}

.driver-header {
    height: 30%;
}

.form-container {
    width: 100%;
    height: 75%;
}

.form-cadastro{
    width: 90%;
}

.driver-input{
    width: 100%;
    padding: 10px;
    outline: none;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

.driver-input::placeholder {
    color: #000
    
}

.select-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.select-div select {
    color: grey;
    width: 48%;
    padding: 10px;
    outline: none;
    margin-top: 15px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

.driver-img{
    width: 10vw;
    min-width: 280px;
}
.driver-btn{
    padding: 10px;
    width: 30%;
    background-color: #3b3b3b;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 90%;
    cursor: pointer;
    text-align: center;
    transition: 0.3s all ease-in-out;
}

.driver-btn:hover{
    background-color: #afafaf;
    transition: 0.3s all ease-in-out;
}

