.container{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-image: url('../images/teste.jpg');
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    position: fixed;
    background-size:cover;  
    overflow: auto;
}

.full-flex {
    display: flex;
    justify-content: center;
    align-items: center;
} 

.justify-flex {
    display: flex;
    justify-content: center;
}

.align-flex{ 
    display: flex;
    align-items: center;
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.full-wh{
    width: 100%;
    height: 100%;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}